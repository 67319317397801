/*
 This is where all the app specific code will go.
 */

$(document).ready(function() {
	function toggleNavbarMethod() {
		if ($(window).width() > 767) {
			$('.navbar .dropdown').on('mouseover', function(){
				$(this).addClass('open');
				$(this).find("a:first").attr("aria-expanded", "true");
			}).on('mouseout', function(){
				$(this).removeClass('open');
				$(this).find("a:first").attr("aria-expanded", "false");
			});
		}
		else {
			$('.navbar .dropdown').off('mouseover').off('mouseout');
		}
	}
	toggleNavbarMethod();
	$(window).resize(toggleNavbarMethod);
});

var module_flooringadvice = new Array();
var flooringAdviceWidth = $(window).width()+0.1+'px';
if($(window).width() > 480) flooringAdviceWidth = $(window).width()/2+0.1+'px';
if($(window).width() > 768) flooringAdviceWidth = '';

$(window).resize(function(){
	flooringAdviceWidth = $(window).width()+0.1+'px';
	if($(window).width() > 480) flooringAdviceWidth = $(window).width()/2+0.1+'px';
	if($(window).width() > 768) flooringAdviceWidth = '';
	$('.no-border-gallery').each(function(){
		$(this).find('.gallery-cell').css('width',flooringAdviceWidth);
	});
	calculateNoSlideHeight();
})

function calculateNoSlideHeight(){
	$('.video-no-slide').each(function(){
		$(this).height(Math.round($(this).width()*$(this).data('ratio'))+'px');
		$(this).find('iframe').height(Math.round($(this).width()*$(this).data('ratio'))+'px');
	});
}

var options_starting_value = new Array;

$(document).ready(function () {
	calculateNoSlideHeight();
	
	//add scroll to top button
    $('body').append('<div id="toTop" class="btn"><span class="glyphicon glyphicon-chevron-up"></span></div>');
    $('body').append('<div id="toPrevious" class="btn">'+translation.back+'</div>');
	$(window).scroll(function () {
		if ($(this).scrollTop() != 0) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}
	}); 
	$('#toTop').click(function(){
	    $("html, body").animate({ scrollTop: 0 }, 600);
	    return false;
	});
	$('#toPrevious').click(function(){
		history.back(-1);
	})
	// the alert div
	if ($(".flash-message").length) {
		$(".flash-message").delay(4500).slideUp();
	}

// stop gallery video player when clicking on the arrows
	$(".stop_player").click(function () {
		$(".player").each(function () {
			$(this).data("flowplayer").stop();
		});
		
	   
	});
	
	
	$('.module-gallery-with-filter > .grid').each(function(){
		var gallery = $(this).parent();
		var $grid = $(this).isotope({
			// options
			itemSelector: '.grid-item',
			percentPosition: true,
			layoutMode: 'packery',
			stamp: '.stamp',
			packery: {
				// columnWidth: '.grid-sizer'
			}
		});
	
		// layout Isotope after each image loads
		$grid.imagesLoaded().progress( function() {
			$grid.isotope('layout');
		});
		
		$grid.isotope({ filter: $(gallery).find('.filter .active a').data('filter') });

		$(gallery).find('.filter a').on( 'click', function() {
			var selector = $(this).data('filter');
			$grid.isotope({ filter: selector });

			// activate the nav-pill
			$(this).parent().siblings().removeClass('active');
			$(this).parent().addClass('active');
		});	
	})
	
	// isotope gallery
	//
	//
	$('.no-border-gallery').each(function(){
		$(this).find('.gallery-cell').css('width',flooringAdviceWidth);
		module_flooringadvice[$(this).attr('id')] = $(this).find('.gallery:not(.gallery-1-columns)').flickity({
			wrapAround: true, // infinite scroll
			cellAlign: 'right',
			imagesLoaded: true,
			prevNextButtons: false,
			pageDots: false,
			autoPlay: 4500,
		});
		$(this).find('.button-prev').on( 'click', function() {
			module_flooringadvice[$(this).parents('.no-border-gallery').attr('id')].flickity( 'previous', true );
		});
		$(this).find('.button-next').on( 'click', function() {
			module_flooringadvice[$(this).parents('.no-border-gallery').attr('id')].flickity( 'next', true );
		});
	})

	// hide on startup
	$('.no-border-gallery').hide();
	
	// show on click
	$('[id^=show-space-]').click(function(){
		
		$('.no-border-gallery').hide();
		scrollToShowSpace($(this));
		
		showShowSpace($(this));
		
		window.location.hash = $(this).attr('id').replace('show-space',translation.flooringadvice);
	});
	
//teaser
	$('.module-teasers').each(function(){
		var module_teasers = $(this).find('.gallery').flickity({
			wrapAround: true, // infinite scroll
			cellAlign: 'left',
			imagesLoaded: true,
			prevNextButtons: false,
			pageDots: false,
			autoPlay: 4500,
		});

		$(this).find('.teasers-prev').on( 'click', function() {
			module_teasers.flickity( 'previous', true );
		});
		$(this).find('.teasers-next').on( 'click', function() {
			module_teasers.flickity( 'next', true );
		});
	})
	if(typeof pinit != 'undefined') {
		addPinterestButtonsToImages();
	}
	
// slider header
	$('.tp-banner').css('visibility', 'visible').show().each(function(){
		var startwidth = 1920;
		var startheight = 936;
		var navigationtype = "bullet";
		if($(this).data('start-width') != undefined) startwidth = $(this).data('start-width'); 
		if($(this).data('start-height') != undefined) startheight = $(this).data('start-height'); 
		if($(this).data('navigation-type') != undefined) navigationtype = $(this).data('navigation-type'); 
		
		$(this).revolution(
		{
			dottedOverlay: "none",
			delay: 5000,
			startwidth: startwidth,
			startheight: startheight,
			hideThumbs: 0,
			thumbWidth: 100,
			thumbHeight: 50,
			thumbAmount: 5,
			navigationType: navigationtype,
			navigationArrows: "solo",
			navigationStyle: "round",
			touchenabled: "on",
			onHoverStop: "on",
			swipe_velocity: 0.7,
			swipe_min_touches: 1,
			swipe_max_touches: 1,
			drag_block_vertical: false,
			parallax: "mouse",
			parallaxBgFreeze: "on",
			parallaxLevels: [7, 4, 3, 2, 5, 4, 3, 2, 1, 0],
			keyboardNavigation: "off",
			navigationHAlign: "center",
			navigationVAlign: "bottom",
			navigationHOffset: 0,
			navigationVOffset: 20,
			soloArrowLeftHalign: "left",
			soloArrowLeftValign: "center",
			soloArrowLeftHOffset: 20,
			soloArrowLeftVOffset: 0,
			soloArrowRightHalign: "right",
			soloArrowRightValign: "center",
			soloArrowRightHOffset: 20,
			soloArrowRightVOffset: 0,
			shadow: 0,
			fullWidth: "on",
			fullScreen: "off",
			spinner: "spinner4",
			stopLoop: "on",
			stopAfterLoops: -1,
			stopAtSlide: -1,
			shuffle: "off",
			autoHeight: "off",
			forceFullWidth: "off",
			hideTimerBar:"on",
			hideThumbsOnMobile: "off",
			hideNavDelayOnMobile: 1500,
			hideBulletsOnMobile: "off",
			hideArrowsOnMobile: "off",
			hideThumbsUnderResolution: 0,
			hideSliderAtLimit: 0,
			hideCaptionAtLimit: 0,
			hideAllCaptionAtLilmit: 0,
			startWithSlide: 0,
			videoJsPath: "rs-plugin/videojs/",
			fullScreenOffsetContainer: "#mainmenu"
		});
	})
//contact
	$('.plan-route-form').submit(function(e){
		e.preventDefault();
		var url = 'https://www.google.com/maps/dir/Noordeinde+2+3341+LW+Hendrik-Ido-Ambacht/'+encodeURIComponent($(this).find('.plan-route-address').val());
		$(this).find('.plan-route-address').val('');
		window.open(url, '_blank');
	})
	
	$('.ajax-form input[type="submit"]').each(function(){
		$(this).attr('data-value',$(this).val());
	}) 
	$('.ajax-form').submit(function(e){
		e.preventDefault();
		var form = $(this);
		$(this).find('.custom-alerts').remove();
		var submit = $(this).find('input[type="submit"]'); 
		if($(submit).val() == $(submit).data('value')){
			$(submit).val(translation['sending']);
			$.ajax({
				url: $(this).data('action'),
				type: 'POST',
				dataType: 'json',
				data: $(this).serialize(),
				success: function (res) {
					if(res.errors != undefined) {
						var warning = '';
						for(a in res.errors) {
							warning += res.errors[a]+'<br/>';
						}
						$(form).prepend('<div class="custom-alerts alert alert-danger fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">x</button>'+warning+'</div>');
						$(submit).val($(submit).data('value'));
					}else{
						var thankyou_redirect = $(form).find('#thankyou_redirect').val();
						if(thankyou_redirect != undefined) {
							window.location.href=thankyou_redirect;
							return;
						}
						
						if(typeof externalTrackingActive != 'undefined') {
							var v = $('#ecommerce_form_name').val();
							if($(form).find('input[name=produktinteresse]:checked').val() != undefined) {
								v = $(form).find('input[name=produktinteresse]:checked').val()
							}
							var orderNum = getOrderID();
							ga('ecommerce:addTransaction', {
								'id': orderNum,
								'affiliation': currentLanguage,
								'revenue': '1',
								'shipping': '',
								'tax': ''
							});
							ga('ecommerce:addItem', {
							    'id': orderNum,
							    'name': $(form).find('#ecommerce_form_name').val(),
							    'sku': $(form).find('#ecommerce_form_name').val(),
							    'category': v,
							    'price': '1',
							    'quantity': '1',
							    'currency': ''
							});
							ga('ecommerce:send');
						}else{
							console.log('not tracking');
						}
						$(form).prepend('<div class="custom-alerts alert alert-success fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">x</button>'+$(form).data('sent')+'</div>');
						$(submit).val(translation['sent']);
					}
				},
				error: function (error) {
					$(form).prepend('<div class="custom-alerts alert alert-danger fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">x</button>'+translation['something_went_wrong']+'</div>');
					$(submit).val($(submit).data('value'));
				}
			});
		}
		
	})
//textblock
	$('.btn-request-sample').click(function(e){
		e.preventDefault();
		if($(this).parents('.module-textblock').nextAll('.module-color-manager').first().attr('class') != undefined) {
			$("html, body").animate({ scrollTop: ($(this).parents('.module-textblock').nextAll('.module-color-manager').first().offset().top-40)+"px" });
		}else if($('.module-color-manager').first().attr('class') != undefined) {
			$("html, body").animate({ scrollTop: ($('.module-color-manager').first().offset().top-40)+"px" });
		}else{
			window.location.href = colorManagerUrl;
		}
	})
	
	$('.btn-request-brochure').click(function(e){
		e.preventDefault();
		if($('.module-brochure').first().attr('class') != undefined) {
			$("html, body").animate({ scrollTop: ($('.module-brochure').first().offset().top-80)+"px" });
		}else{
			if($(this).data('hash') == undefined) $(this).data('hash','');
			window.location.href = brochureServiceUrl+$(this).data('hash');
		}
	})
	$('.btn-scroll-to-flooring-advice').click(function(){
		if($('.module-flooring-advice').first().attr('class') != undefined) {
			$("html, body").animate({ scrollTop: ($('.module-flooring-advice').first().offset().top-100)+"px" });
		}
	})
	
//color manager
	$('.color-manager-formulering').each(function(){
		if($(this).val() != undefined && $(this).val() != ''){
			fillColors($(this).parents('.cm-color-picker'));
		}
	})
	$('.color-manager-formulering').change(function(){
		if($(this).val() != ''){
			fillColors($(this).parents('.cm-color-picker'));
		}
	})
	
	$('.cm-step').each(function(){
		options_starting_value[$(this).attr('id').substr(8)] = $(this).html();
	})
	
	$('.cm-color-picker').each(function(){
		if($(this).find('input.cm-step:last').attr('id') != undefined) {
			changeStepContent($(this).find('input.cm-step:last'))
		}
		
	})
	$('.cm-navigation .glyphicon').click(function(){
		if($(this).parents('.active').hasClass('active')) {
			var flake_id = $(this).parent().attr('href').substr(-3,1);
			$('.color-manager-flake-'+flake_id).find(last_chosen_flakes[flake_id]).trigger('click');
		}else{
			changeFlake = true;
		}
	})
	$('.cm-navigation a').on('shown.bs.tab', function(event){
		if(changeFlake) {
			var flake_id = $(event.target).attr('href').substr(-3,1);
			$('.color-manager-flake-'+flake_id).find(last_chosen_flakes[flake_id]).trigger('click');
		}
		changeFlake = false;
	});
	
	$('.cm-step').change(function(){
		changeStepContent($(this));
	})

// HASH
var hash = window.location.hash;
//brochures
if(hash != '' && !hash.match(/=/) && $(hash+'-anchor').attr('id') != undefined) {
	
	$("html, body").animate({ scrollTop: ($(hash+'-anchor').offset().top-80)+"px" });
	//and trigger open brochure.
	$(hash+'-anchor').next('.row').find('.brochure-button').trigger('click');
}
//flooring advice
if(hash != '' && !hash.match(/=/) && hash.match('#'+translation.flooringadvice+'-')) {
	var button = hash.replace(translation.flooringadvice,'show-space');
	$('#'+$(button).attr('id').replace('show-','gallery-')).show();
}

//page scroll
	var scroll_modules = new Array();

	var t = 0;
	
	$('.affix').each(function(){
		
		scroll_modules[t] = $(this).parents('.module-page-scroll-navigation');

		$(this).affix({
			offset: {
				top: function () {
					var offset = $(scroll_modules[t]).offset();
					var text_height = $(scroll_modules[t]).find('.page-scroll-text').height();
					return (this.top = offset.top-(5-text_height+(t*38)));
				},
				bottom: function () {
					return (this.bottom = $('#footer').outerHeight(true));
				}
			}
		});
		
		var nonAffixContainer = $(scroll_modules[t]).find('.container').clone();
		nonAffixContainer = $(nonAffixContainer).removeAttr('class').removeAttr('id').addClass('non-affix hidden-xs');
		$(scroll_modules[t]).after(nonAffixContainer);
		nonAffixContainer = $(nonAffixContainer).attr('style','height:'+($(nonAffixContainer).height()+10)+'px').html('');
		if(!$(scroll_modules[t]).find('.container').hasClass('affix')) {
			$(nonAffixContainer).addClass('hidden');
		}
		
		$(scroll_modules[t]).on('affixed.bs.affix', function () {
			$(this).next('.non-affix').removeClass('hidden');
			
			$('.header-panel').attr('style','height:'+($('.affix').length*38)+'px');
			if($('.affix').length > 1) {
				$(this).find('.container').attr('style','top:'+($('#header .navbar-fixed-top').height()+($('.affix').length-1)*38)+'px');
			}
		});
		$(scroll_modules[t]).on('affixed-top.bs.affix', function () {
			$(this).next('.non-affix').addClass('hidden');
			var height = 38;
			if($('.affix').length > 1) height = ($('.affix').length*38);
			$('.header-panel').attr('style','height:'+height+'px');
		});
		t++;
	})
	
	if($('.affix').length > 1) {
		$('.header-panel').attr('style','height:'+($('.affix').length*38)+'px');
	}
	$('.module-page-scroll-navigation-container').each(function(){
		if($(this).offset().top-$(window).scrollTop() < 40 && !$(this).find('.page-scroll-text').hasClass('transparent-text')){
			$(this).find('.page-scroll-text').addClass('transparent-text');
		}
	});
	$('body').scrollspy({ target: ".module-page-scroll-navigation", offset: ((38*2)+$('#header .navbar').height()+2) })

	//jQuery for page scrolling feature - requires jQuery Easing plugin
	$(function() {
		$('a.page-scroll').bind('click', function(event) {
			var $anchor = $(this);
			$('html, body').stop().animate({
				scrollTop: ($($anchor.attr('href')).offset().top)-($('#header .header-panel').height()+$('#header .navbar').height())
			}, 1200, 'easeInOutExpo');
			event.preventDefault();
		});

	});
//accordion
	$('.accordion').find('.tp-banner').revpause();
	$('.accordion').on('show.bs.collapse', function () {
		$(this).find('.tp-banner').revredraw();
		$('.accordion').find('.tp-banner').revresume();
	});
	$('.accordion').on('shown.bs.collapse', function () {
		$(this).find('.grid').isotope('layout');
	});

	$('.accordion').on('hide.bs.collapse', function () {
		$('.accordion').find('.tp-banner').revpause();
	});

// prevent save image as..
	$('body').on('contextmenu', 'img', function(e){ return false; }); 
	
});

$(window).load(function(){
	// HASH flooring advice
	var hash = window.location.hash;
	if(hash != '' && !hash.match(/=/) && hash.match('go-to-'+translation.flooringadvice+'-')) {
		var button = hash.replace('go-to-'+translation.flooringadvice,'show-space');
		scrollToShowSpace($(button));
		showShowSpace($(button));
		window.location.hash = hash.replace('go-to-'+translation.flooringadvice,translation.flooringadvice);
	}

//page scroll
	var t = 0;
	if($('.affix').length > 1) {
		$('.affix').each(function(){
			$(this).parents('.module-page-scroll-navigation').next('.non-affix').removeClass('hidden');
			if(t > 0) {
				$(this).attr('style','top:'+($('#header .navbar-fixed-top').height()+(t*38))+'px');
			}
			t++;
		})
	}
	if($('#navbar-collapse-1').css('display') == 'block') {
		$('.dropdown-menu').attr('style','max-height:'+($(window).height()-$('.navbar-header').height())+'px;');
	}
});
$(window).resize(function(){
	//page scroll
	var t = 0;
	if($('.affix').length > 1) {
		$('.affix').each(function(){
			$(this).parents('.module-page-scroll-navigation').next('.non-affix').removeClass('hidden');
			if(t > 0) {
				$(this).attr('style','top:'+($('#header .navbar-fixed-top').height()+(t*38))+'px');
			}
			t++;
		})
	}
	if($('#navbar-collapse-1').css('display') == 'block') {
		$('.dropdown-menu').attr('style','max-height:'+($(window).height()-$('.navbar-header').height())+'px;');
	}else{
		$('.dropdown-menu').removeAttr('style');
	}
});
$(window).scroll(function(){
	$('.module-page-scroll-navigation-container').each(function(){
		if($(this).offset().top-$(window).scrollTop() < 40 && !$(this).find('.page-scroll-text').hasClass('transparent-text')){
			$(this).find('.page-scroll-text').addClass('transparent-text');
		}else if($(this).offset().top-$(window).scrollTop() > 40 && $(this).find('.page-scroll-text').hasClass('transparent-text')){
			$(this).find('.page-scroll-text').removeClass('transparent-text');
		}
	})
	
})
//color manager functions
var changeFlake = false;
function changeStepContent(currentStep){
	var currentColorPicker = $(currentStep).parents('.cm-color-picker');
	$(currentColorPicker).find('.cm-step').each(function(){
		if($(currentStep).attr('id').substr(8,1) < $(this).attr('id').substr(8,1)) {
			var value = $(this).val();
			$(this).html(options_starting_value[$(this).attr('id').substr(8)]);
			$(this).find('option').each(function(){
				if($(currentStep).val() != '' && $(this).val() != '' && $(currentStep).val() != $(this).data('step'+$(currentStep).attr('id').substr(8,1))) {
					$(this).remove();
				}
			})
			if($(this).find('option[value="'+value+'"]').val() != undefined){
				$(this).val(value);
			}
		}
	})
	if($(currentColorPicker).find('.color-manager-formulering').data('val') != $(currentColorPicker).find('.color-manager-formulering').val()) {
		cleanUp($(currentColorPicker));
	}
}

var last_chosen_flakes;
function fillColors(colorManager){
	last_chosen_flakes = new Array();
	/***
		draw color options.
	***/
	
	if($(colorManager).find('.color-manager-formulering').val() != '') {
		$(colorManager).find(".color-manager-grid").html('<div class="wait"><span class="glyphicon glyphicon-hourglass spinning"></span></div>');
	}

	// fetch the colors and draw the color spaces
	$.ajax({
		url: $(colorManager).data('action'),
		type: 'POST',
		dataType: 'json',
		data: $(colorManager).serialize(),
		success: function (data) {
			var teller = 1;
			if(data != null){
				$(colorManager).parents('.module-color-manager-container').find('.cm_formulering').html(data.formulering);
				$(colorManager).find('.color-manager-grid').html('');
				$(colorManager).find('.cm-navigation').find('a:first').trigger('click');
				$(colorManager).find('.cm-navigation').addClass('hidden');
				if(data.kleur.length > 0) {
					$.each(data.kleur,function(i,color){
						var colorField = $(colorManager).find('.cm-color-template').clone();
						$(colorField).addClass('cm-color-'+teller);
						$(colorField).removeClass('cm-color-template');
						$(colorField).data('kleur_hex_codering',color.kleur_hex_codering);
						$(colorField).data('kleur_label',color.kleur_label);
						$(colorField).data('kleur_type',color.kleur_type);
						$(colorField).data('kleur_code',color.kleur_code);
						$(colorField).data('kleur_afbeelding',color.kleur_afbeelding);
						if(color.kleur_type == 'afbeelding') {
							$(colorField).find('.color').css('background','url("'+CdnPath+'assets/img/color_manager/'+color.kleur_afbeelding+'") center center');
							$(colorField).appendTo($(colorManager).find('.color-manager-grid'));
						}else if(color.kleur_type == 'smaak') {
							$(colorField).removeAttr('id');
							$(colorField).data('id','cm-color-'+teller);
							$(colorField).addClass('cm-color-'+teller);
							$(colorManager).find('.cm-navigation').removeClass('hidden');
							$(colorField).find('.color').css('background','#'+color.kleur_hex_codering);
							// Exception Bolidtop 500 Deco has 1 flake with own image:
							if(data.formulering_ID==41) {
								$(colorField).find('.color').css('background','url("'+CdnPath+'assets/img/color_manager/'+color.kleur_afbeelding+'") center center');
							}
							$(colorField).appendTo($(colorManager).find('.color-manager-flake'));
							
							$(colorManager).find('.cm-flake-container').removeClass('hidden');
							if(last_chosen_flakes.length == 0) {
								for(x=1;x<=4;x++) {
									last_chosen_flakes[x] = '.cm-color-'+teller;
								}
							}
						}else {
							$(colorField).find('.color').css('background','#'+color.kleur_hex_codering);
							$(colorField).appendTo($(colorManager).find('.color-manager-grid'));
						}
						teller++;
					});
					// Exception Bolidtop 500 Deco has 1 flake:
					if(data.formulering_ID==41) {
						$('.cm-navigation li:nth-last-child(-n+3)').hide();
					} else {
						$('.cm-navigation li:nth-last-child(-n+3)').show();
					}
				} else {
					$(colorManager).find('.tab-content').append('<div class="col-md-12 error">'+data.error+'</div>');
				}
				$(colorManager).find('.color-manager-grid .color-manager-cell').click(function(){
					showExample($(this));
				})
				$(colorManager).find('.color-manager-flake .color-manager-cell').click(function(){
					showExampleFlake($(this));
				})
			}
		},
		error: function (error) {
			$('.cm-color-picker').prepend('<div class="custom-alerts alert alert-danger fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">x</button>'+translation['something_went_wrong']+'</div>');
		}
	})
	
	//open sample popup
	$('.btn-open-sample-request').click(function(){
		var href = $(this).attr('href');
		var button = $(this);
		if(href == undefined) href = $(this).data('href');
		eModal.ajax({
			url: href,
			title: $(this).data('title'),
			size: $(this).data('size'),
			buttons: false
		}).then(function (e) {
			$(e).find('#sample_color_values').val(JSON.stringify($(button).parents('form').serializeJSON()));
			$(e).find('#page').val($(button).parents('form').find('#page').val());
		});
	})
	
}	

//open cookie warning popup
function openCookieWarningPopup(){
	if(Cookies.get('accepted_cookies') == undefined) {
		eModal.ajax({
			url: '/'+currentLanguage+'/cookie_warning',
			title: translation.cookie_popup_title,
			size: 'xl',
			buttons: false,
			closeOnlyWithButton: true
		});
	}
}

function showExample(color) {
	var colorManager = $(color).parents('.cm-color-picker');
	
	/***
		show the chosen floor color.
	***/
	if($(color).hasClass('active')) {
		color = '';
	}
	
	
	$(colorManager).find('.color-manager-grid .color-manager-cell').removeClass('active');
	$(colorManager).find('.cm-example-color').css("background","");
	$(colorManager).find('.cm-example-rgb, .cm-example-hex, .cm-example-color-code').html('');
	$(colorManager).find('.btn-open-sample-request').addClass('hidden');
	if(color != '') {
		$(colorManager).find('.btn-open-sample-request').removeClass('hidden');
		
		$(color).addClass('active');
		var hex_coding = $(color).data('kleur_hex_codering');
		
		if($(color).data('kleur_type')=='basiskleur') {
			if(color != '') {
				$(colorManager).find('.cm-example-color').css("background",'#'+hex_coding);
			}
			$(colorManager).find('.cm-example-rgb').html(hexToRgb("#"+hex_coding).r +' '+hexToRgb("#"+hex_coding).g +' '+hexToRgb("#"+hex_coding).b);
			$(colorManager).find('.cm-example-hex').html('#'+hex_coding);
			
		}
		else if($(color).data('kleur_type')=='afbeelding') {
			$(colorManager).find('.cm-example-color').css("background","url("+CdnPath+"assets/img/color_manager/"+$(color).data('kleur_afbeelding')+") center center");
		}
		$(colorManager).find('.cm-example-color-code').html($(color).data('kleur_code'));
		$(colorManager).find('.cm-example-color-label').html($(color).data('kleur_label'));
		
		//	Fill in the form values for the sample request.
		$(colorManager).find('.cm-color-code').val($(color).data('kleur_code'));

	}
	
}

function showExampleFlake(color) {
	var colorManager = $(color).parents('.cm-color-picker');
	/***
		functie om de gekozen flake en de bijbehorende waarden te tonen
	***/
	var flake_manager_id = $(colorManager).find('.cm-navigation .active a').attr('href');
	
	if($(color).hasClass('active')) {
		color = '';
	}
	
	$(flake_manager_id).find('.color-manager-cell').removeClass('active');
	$(flake_manager_id.replace('color-manager','cm-example')).removeAttr('style');
	$(colorManager).find('.cm-example-flake').html('');
	$(colorManager).find('.cm-navigation .active .glyphicon').removeClass('glyphicon-check').addClass('glyphicon-unchecked');
	
	$(color).addClass('active');
	
	$(colorManager).find('.color-manager-flake').each(function(){
		if($(this).find('.active').data('kleur_code') != undefined){
			$(colorManager).find('.cm-example-flake').html($(colorManager).find('.cm-example-flake').html()+$(this).find('.active').data('kleur_code')+'<br/>');
		}
	})
	if(color != '') {
		var classes = $(color).attr('class').split(' ');
		for(x in classes) {
			if(classes[x].substr(0,9) == 'cm-color-') {
				last_chosen_flakes[flake_manager_id.substr(-3,1)] = '.'+classes[x];
			}
		}
		var imgUrl = 'url('+CdnPath+'assets/img/color_manager/flakes/flake_'+$(color).data('kleur_hex_codering')+'.gif)';

		// Exception Bolidtop 500 Deco has 1 flake with own image:
		var flakeImg = $(color).find('.color').css('background-image');
		if(flakeImg != 'none') {
			imgUrl = flakeImg;
		}
		
		$(flake_manager_id.replace('color-manager','cm-example')).css("backgroundImage", imgUrl );
		$(colorManager).find('.cm-navigation').find('.active .glyphicon').removeClass('glyphicon-unchecked').addClass('glyphicon-check');
	}
	
	$(flake_manager_id.replace('color-manager','cm-color-code')).val($(color).data('kleur_code'));
	
}

function cleanUp(colorManager) {
	/***
		functie om waarden op scherm en in formulier op te schonen
	***/
	$(colorManager).find('.color-manager-grid .color-manager-cell').removeClass('active');
	$(colorManager).find('.flake, .cm-example-color').removeAttr("style");
	$(colorManager).find('.cm-navigation .glyphicon').removeClass('glyphicon-check').addClass('glyphicon-unchecked');
	$(colorManager).find('.cm-navigation a:first').trigger('click');
	$(colorManager).find('.cm-navigation, .cm-flake-container').addClass('hidden');
	$(colorManager).parents('.module-color-manager-container').find('.cm_formulering').html('');
	$(colorManager).find('.color-manager-flake, .color-manager-grid, .cm-example-rgb, .cm-example-hex, .cm-example-color-code, .cm-example-flake').html('');
	$(colorManager).find('.cm-color-code').val('');
	for(a=1;a<5;a++) {
		$(colorManager).find('.cm-color-code-flake-'+a).val('');
	}
	$(colorManager).find('.btn-open-sample-request').addClass('hidden');
}

function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}


//brochures
$('.brochure-button').on('click',function(e){
	e.preventDefault();
	var href = $(this).attr('href');
	if(href == undefined) href = $(this).data('href');
	eModal.iframe(href, $(this).data('title'));
})
$('.btn-open-brochure-request').click(function(e){
	e.preventDefault();
	var href = $(this).attr('href');
	var button = $(this);
	if(href == undefined) href = $(this).data('href');
	eModal.ajax({
		url: href,
		title: $(this).data('title'),
		size: $(this).data('size'),
		buttons: false
	}).then(function (e) {
		$(e).find('#page').val($(button).parents('form').find('#page').val());
	});
})

function getOrderID(){
	var randomNumber = Math.floor(Math.random()*1000);
	var currentTime = new Date();
	var yyyy = currentTime.getFullYear();
	var mm = currentTime.getMonth()+1; // January is 0!
	var dd = currentTime.getDate();
	var hh = currentTime.getHours();
	var ii = currentTime.getMinutes();
	var ss = currentTime.getSeconds();
	
	if(dd<10) { dd='0'+dd; } 
	if(mm<10) { mm='0'+mm; } 
	if(hh<10) { hh='0'+hh; } 
	if(ii<10) { ii='0'+ii; } 
	if(ss<10) { ss='0'+ss; }
	 
	timeStamp = yyyy + mm + dd + "-" + hh + ii + ss  + "-" + randomNumber;
	return timeStamp;    	
}

//flooring advice

function scrollToShowSpace(deze){
	$('#'+$(deze).attr('id').replace('show-','gallery-')).fadeIn(600,function(){
		$('html, body').stop().animate({
			scrollTop: $(this).position().top-70
		}, 1500, 'easeOutExpo');
	});
}
function showShowSpace(deze) {
	$('#'+$(deze).attr('id').replace('show-','gallery-')).find('.gallery-cell').css('width',flooringAdviceWidth);
	module_flooringadvice[$(deze).attr('id').replace('show-','gallery-')].flickity('resize');
}


var options = [];
function newsletter_dropdown(){
		
	$('.button-group .dropdown-menu a').unbind('click');
	$( '.button-group .dropdown-menu a' ).on( 'click', function( event ) {
		
	   var $target = $( event.currentTarget ),
	       val = $target.attr( 'data-value' ),
	       form = $target.parents('.opt-in').attr( 'data-form' ),
	       choosen_segments = $target.parents('.opt-in').find('.choosen_segments'),
	       $inp = $target.find( 'input' ),
	       idx;
	   
	   if(options[form] == undefined) options[form] = [];
	   if ( ( idx = options[form].indexOf( val ) ) > -1 ) {
			options[form].splice( idx, 1 );
			setTimeout( function() { $inp.prop( 'checked', false ) }, 0);
	   } else {
		options[form].push( val );
		setTimeout( function() { $inp.prop( 'checked', true ) }, 0);
	   }
	   // fill the 'hidden' choosen_segments field so the required attribute can be checked
	   $(choosen_segments).val(options[form]);
	   $( event.target ).blur();
	   
	   return false;
	});

}
function addPinterestButtonsToImages(){
	$('section img').each(function(){
		if($(this).parents('.pinterest-button').attr('class') == undefined && $(this).parents('.slotholder').attr('class') == undefined) {
			$(this).before($('<div class="pinterest-button"><a data-pin-do="buttonPin" data-pin-tall="true" data-pin-save="true" href="https://www.pinterest.com/pin/create/button/" data-pin-media="'+$(this).attr('src')+'"></a></div>'));
		}
	})
	$(".slotholder").each(function(){
		if($(this).parent().find('iframe').attr('id') == undefined) {
			$(this).before($('<div class="pinterest-button"><a data-pin-do="buttonPin" data-pin-tall="true" data-pin-save="true" href="https://www.pinterest.com/pin/create/button/" data-pin-media="'+$(this).find('.tp-bgimg').attr('src')+'"></a></div>'));
		}
   });
}